import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { Paper } from 'components';
import useStyles from './styles';

export default function EmptyContent ({ text, icon: Icon, children, onlyMessage, ...other }) {
  const classes = useStyles();
  const Component = onlyMessage ? Box : Paper;
  const props = {};
  if (!onlyMessage) {
    props.p = 2;
  }
  return (
    <Component {...props} {...other}>
      <div className={classes.container}>
        {!!Icon && <Icon fontSize="large" />}
        <Typography className={classes.text}>
          {text}
        </Typography>
      </div>
      {children}
    </Component>
  );
}

EmptyContent.propTypes = {
  text: PropTypes.string.isRequired,
  onlyMessage: PropTypes.bool,
  icon: PropTypes.any,
  children: PropTypes.node
};

EmptyContent.defaultProps = {
  icon: InfoIcon,
  component: Paper
};
