import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLink } from 'hooks';

const PolicyLinkComponent = forwardRef(function Component (props, ref) {
  return <Link {...useLink(props)} ref={ref} />;
});

PolicyLinkComponent.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })])
};

export default memo(PolicyLinkComponent);
