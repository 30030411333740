import { makeStyles } from '@material-ui/core/styles';
import logo from 'assets/logo-nema_simbolo-cores_rgb_fundo_escuro.png';
import { getLogoHeight } from 'constant';
const width = 48;

export default makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  logo: {
    width,
    height: getLogoHeight(width),
    backgroundImage: `url(${logo})`,
    backgroundColor: theme.palette.secondary.main,
    backgroundBlendMode: 'hard-light',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  },
  brandName: {
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 600,
    fontFamily: 'Titillium Web'
  },
  icon: {
    marginRight: theme.spacing(),
    display: 'block'
  },
  userInfo: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1
  },
  userRole: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 2,
    fontSize: theme.typography.pxToRem(14)
  },
  menu: {
    width: 250
  },
  menuList: {
    overflowY: 'auto'
  },
  listIcon: {
    minWidth: 46
  },
  listItem: {
    '&.active': {
      backgroundColor: theme.palette.action.selected
    }
  },
  iconButton: {
    '&.active': {
      color: theme.palette.common.white
    }
  }
}));