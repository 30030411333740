import { useState, useCallback, useMemo } from 'react';

export default function useFormError () {
  const [errors, setErrors] = useState();

  const setError = useCallback((prop, error) => {
    setErrors(prev => ({ ...prev, [prop]: error }));
    return () => setErrors(prev => ({ ...prev, [prop]: null }));
  }, []);

  const data = useMemo(() => {
    let errorCount = 0;
    for (const key in errors) {
      if (errors[key]) {
        errorCount++;
      }
    }
    const hasError = errorCount > 0;
    return { errorCount, hasError, isValid: errors ? !hasError : null };
  }, [errors]);

  return { ...data, errors, setError };
}
