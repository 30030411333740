import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Divider } from '@material-ui/core';

function DataFormFieldsetComponent ({ title, children, forceDisplay, divider, ...other }) {
  delete other.setError;

  if (!forceDisplay && !title && !children) {
    return null;
  }

  return (
    <Box {...other}>
      {!!title && <Typography variant="h5" component="h3">{title}</Typography>}
      {children}
      {divider && <Divider />}
    </Box>
  );
}

DataFormFieldsetComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  forceDisplay: PropTypes.bool,
  divider: PropTypes.bool
};

export default memo(DataFormFieldsetComponent);
