import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledTextField = styled(TextField)(compose(spacing));

function SearchFieldComponent ({ onSearch, delay, ...props }) {
  const [value, setValue] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => onSearch(value), delay);
    return () => clearTimeout(timeoutId);
  }, [delay, onSearch, value]);

  return (
    <StyledTextField
      variant="outlined"
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
      onChange={evt => setValue(evt.target.value)}
    />
  );
}

SearchFieldComponent.propTypes = {
  onSearch: PropTypes.func.isRequired,
  delay: PropTypes.number
};

SearchFieldComponent.defaultProps = {
  delay: 800
};

export default memo(SearchFieldComponent);
