import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  flex: {
    display: 'flex',
    flexWrap: ({ wrap }) => wrap ? 'wrap' : 'initial',
    flexDirection: ({ column }) => column ? 'column' : 'row',
    alignItems: ({ align }) => align,
    justifyContent: ({ justify }) => justify,
    '& > * + *': {
      marginLeft: ({ column, spacing }) => column || !spacing ? null : theme.spacing(spacing),
      marginTop: ({ column, spacing }) => column && spacing ? theme.spacing(spacing) : null
    }
  },
  grow: {
    flex: 1
  }
}));
