import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';
import { TableCell } from 'components';
import useStyles from './styles';
import get from 'lodash/get';

function ListData ({ prop, data, parse, align, showFalseIcon, fallback, index }) {
  const classes = useStyles();
  let content = prop === 'index' ? index : get(data, prop);

  if (typeof parse === 'function') {
    content = prop ? parse(content, data, index) : parse(data, index);
  }
  if (content === true) {
    content = <CheckIcon className={classes.success} />;
  } else if (content === false && showFalseIcon) {
    content = <ClearIcon className={classes.error} />;
  }

  return (
    <TableCell align={align}>
      {content ?? <span className="textDisabled">{fallback}</span>}
    </TableCell>
  );
}

ListData.propTypes = {
  prop: PropTypes.string,
  data: PropTypes.object,
  parse: PropTypes.func,
  align: PropTypes.string,
  showFalseIcon: PropTypes.bool,
  fallback: PropTypes.node,
  index: PropTypes.number,

  label: PropTypes.string,
  sortable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

ListData.defaultProps = {
  fallback: '-'
};

export default memo(ListData);
