import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.info[theme.palette.type === 'dark' ? 'light' : 'dark']
  },
  text: {
    marginLeft: theme.spacing()
  }
}));
