import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export { default as CepFormat } from './cep';
export { default as CNPJFormat } from './cnpj';
export { default as CPFFormat } from './cpf';
export { default as DocumentFormat } from './document';
export { default as PhoneFormat } from './phone';

export const findFormat = formats => len => {
  let current = formats[formats.length - 1]?.mask;
  if (!len) {
    return current;
  }
  for (const format of formats) {
    if (len <= format?.size) {
      current = format?.mask;
      break;
    }
  }
  return current;
};

export default function NumberFormatComponent (props) {
  const { onChange, value, inputRef, ...other } = props;

  const onValueChange = useCallback(val => {
    if (val.value !== value) {
      onChange(val.value);
    }
  }, [onChange, value]);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={value}
      onValueChange={onValueChange}
    />
  );
};

NumberFormatComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  inputRef: PropTypes.func
};