import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { NAME } from 'constant';
import useStyles from './styles';

export default function AuthContainerComponent ({ children, title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.bg}>
          <div className={classes.logo} />
          <Typography component="h1" className={classes.brand}>
            {NAME}
          </Typography>
        </div>
        <div className={classes.center}>
          <div className={classes.content}>
            {!!title && (
              <Typography align="center" variant="h5" component="h2">
                {title}
              </Typography>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

AuthContainerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node
};
