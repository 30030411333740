import React, { memo, useEffect, useCallback, useState, Children, useMemo } from 'react';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { Table, TableBody } from '@material-ui/core';
import { InlineForm, ListButton, ListBody, ListHead, ListButtons, ListData, TableCell } from 'components';
import { setDataOnArray } from 'helpers';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const Styled = styled.div(compose(spacing));

const getSelectedStyle = (currentItem, getRowStyle) => (item, index) => {
  let custom = {};
  if (getRowStyle) {
    custom = getRowStyle(item, index);
  }
  if (currentItem === item) {
    return { backgroundColor: grey[200], ...custom };
  }
  return custom;
};

function DataGridComponent ({
  children,
  data,
  onCreate,
  onUpdate,
  onRemove,
  onSelect,
  getRowStyle,
  order,
  direction,
  resourceName,
  onChange,
  onChangeForm,
  onCancel,
  disabled,
  appendToCurrentItem,
  ...other
}) {
  const [currentItem, setCurrentItem] = useState({});
  const [_currentData, _setCurrentData] = useState([]);

  useEffect(() => !onChange && _setCurrentData(data), [data, onChange]);
  useEffect(() => {
    if (appendToCurrentItem) {
      setCurrentItem(prev => setDataOnArray(prev, appendToCurrentItem ));
    }
  }, [appendToCurrentItem]);

  const currentData = useMemo(() => {
    const _data = onChange ? data : _currentData;
    if (!order) {
      return _data;
    }
    return orderBy(_data, order, direction);
  }, [_currentData, data, direction, onChange, order]);
  const setCurrentData = useCallback(fn => onChange ? onChange(fn) : _setCurrentData(fn), [onChange]);

  const handleSubmit = useCallback(async payload => {
    let response;
    if (currentItem.id) {
      response = await onUpdate(payload, currentItem.id);
      if (!onChange) {
        const index = data.findIndex(item => item.id === response.id);
        if (index >= 0) {
          data[index] = response;
        }
      }
    } else {
      response = await onCreate(payload);
      if (!onChange) {
        data.push(response);
      }
    }
    setCurrentData(prev => setDataOnArray(prev, response, { append: false }));
    setCurrentItem({});
    onCancel && onCancel();
  }, [currentItem.id, data, onCancel, onChange, onCreate, onUpdate, setCurrentData]);

  const handleCancel = useCallback(() => {
    setCurrentItem({});
    onCancel && onCancel();
  }, [onCancel]);

  const handleSelect = useCallback(item => {
    setCurrentItem(item);
    onSelect && onSelect(item);
  }, [onSelect]);

  const handleRemove = useCallback(async itemId => {
    await onRemove(itemId);
    setCurrentItem(prev => prev.id === itemId ? {} : prev);
    setCurrentData(prev => prev?.filter(item => item.id !== itemId));
    if (!onChange) {
      const index = data.findIndex(item => item.id === itemId);
      if (index >= 0) {
        data.splice(index, 1);
      }
    }
  }, [data, onChange, onRemove, setCurrentData]);

  const [form, table] = useMemo(() => {
    const tableComponents = [ListData, ListButtons];
    const child = Children.toArray(children);
    return [
      child.filter(c => !tableComponents.includes(c.type)),
      child.filter(c => tableComponents.includes(c.type))
    ];
  }, [children]);

  const submitLabel = useMemo(() => {
    let label = currentItem?.id ? 'Alterar' : 'Incluir';
    if (resourceName) {
      label += ` ${resourceName}`;
    }
    return label;
  }, [currentItem?.id, resourceName]);

  return (
    <Styled {...other}>
      <Table stickyHeader>
        <ListHead>
          {table}
          <TableCell />
        </ListHead>
        <TableBody>
          <ListBody
            data={currentData}
            loading={!data}
            getRowStyle={getSelectedStyle(currentItem, getRowStyle)}
          >
            {table}
            <ListButtons>
              {onUpdate && <ListButton variant="edit" onClick={handleSelect} />}
              {onRemove && <ListButton variant="remove" onClick={handleRemove} />}
            </ListButtons>
          </ListBody>
        </TableBody>
      </Table>
      <InlineForm
        p={3}
        data={currentItem}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        confirmLabel={submitLabel}
        onChange={onChangeForm}
        cancelLabel="Limpar"
        disabled={!data || disabled || (!onCreate && !currentItem.id)}
      >
        {form}
      </InlineForm>
    </Styled>
  );
}

DataGridComponent.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.array,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  resourceName: PropTypes.string,
  getRowStyle: PropTypes.func,
  onChange: PropTypes.func,
  onChangeForm: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  appendToCurrentItem: PropTypes.object
};

export default memo(DataGridComponent);
