import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
import get from 'lodash/get';
import useStyles from './styles';

const hasError = (props, value) => {
  let { validate, required } = props;
  if (typeof validate === 'function') {
    const error = validate(value);
    if (error) {
      return error;
    }
  }
  if (required && !value) {
    return 'required';
  }
  return false;
};

const decode = value => value === null ? '0' : value || '';
const encode = value => value === '0' ? null : value || undefined;

function RadioGroupComponent (props) {
  const { onChange, getLabel, options, prop, value, data, label, helperText, setError, error, emptyLabel, required, disabled, ...other } = props;
  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState(value);
  const _value = prop ? currentValue : value;

  useEffect(() => prop && setCurrentValue(value), [value, data, prop]);

  const validationError = hasError(props, _value);
  useEffect(() => setError && setError(prop, validationError), [prop, setError, validationError]);

  const handleChange = event => {
    const val = event.target.value;
    const parsed = encode(val);
    if (prop) {
      setCurrentValue(val);
      onChange(prop, parsed);
    } else {
      onChange(parsed);
    }
  };

  const isDisabled = useCallback(item => (
    typeof disabled === 'function' ? disabled(item) : disabled
  ), [disabled]);

  const hasValidationError = !!validationError && validationError !== 'required';
  const customProps = {
    ...other,
    error: error || hasValidationError
  };
  if (hasValidationError) {
    customProps.helperText = validationError;
  }
  delete customProps.validate;

  return (
    <FormControl component="fieldset" {...customProps}>
      {!!label && (
        <FormLabel component="legend">{label}</FormLabel>
      )}
      <RadioGroup
        className={classes.radioGroup}
        value={decode(_value)}
        onChange={handleChange}
      >
        {!required && (
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={emptyLabel}
          />
        )}
        {options?.map(item => (
          <FormControlLabel
            key={item.id}
            value={String(item.id)}
            control={<Radio disabled={isDisabled(item)} />}
            label={get(item, getLabel)}
          />
        ))}
      </RadioGroup>
      {!!helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

RadioGroupComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  getLabel: PropTypes.string.isRequired,
  options: PropTypes.array,
  prop: PropTypes.string,
  value: PropTypes.any,
  data: PropTypes.object,
  label: PropTypes.string,
  helperText: PropTypes.string,
  setError: PropTypes.func,
  error: PropTypes.bool,
  emptyLabel: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

  validate: PropTypes.func
};

RadioGroupComponent.defaultProps = {
  emptyLabel: 'Nenhum',
  getLabel: 'name'
};

export default memo(RadioGroupComponent);
