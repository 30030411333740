import React, { memo, useMemo, cloneElement, Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'components';
import CheckItem from './Item';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import useStyles from './styles';

const Styled = styled.div(compose(spacing));

export { default as CheckData } from './Data';

function CheckManagerComponent ({
  children,
  itemProp,
  optionProp,
  onCreate,
  onRemove,
  options,
  optionsGroupBy,
  optionsSortBy,
  selectedItems,
  disabled,
  ...other
}) {
  const groupedOptions = useMemo(() => {
    if (!options) {
      return [];
    }
    if (!optionsGroupBy) {
      return [[null, options]];
    }
    const groups = {};
    for (const item of sortBy(options, optionsSortBy)) {
      const key = get(item, optionsGroupBy);
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    }
    const _groups = [];
    for (const key in groups) {
      _groups.push([key, groups[key]]);
    }
    return sortBy(_groups, '[0]');
  }, [options, optionsGroupBy, optionsSortBy]);
  const classes = useStyles();

  return (
    <Styled {...other}>
      {groupedOptions?.map(([key, opts]) => (
        <Fragment key={key}>
          {!!key && (
            <div className={classes.group}>
              {key}
            </div>
          )}
          {opts?.map(option => {
            const value = option[optionProp];
            return (
              <Flex component="label" className={classes.item} align="center" key={option.id} spacing={1}>
                <CheckItem
                  value={value}
                  item={selectedItems?.find(item => item[itemProp] === value)}
                  onCreate={onCreate}
                  onRemove={onRemove}
                  disabled={disabled || !selectedItems}
                />
                {Children.map(children, child => cloneElement(child, { data: option }))}
              </Flex>
            );
          })}
        </Fragment>
      ))}
    </Styled>
  );
};

CheckManagerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  itemProp: PropTypes.string.isRequired,
  optionProp: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.array,
  optionsGroupBy: PropTypes.string,
  optionsSortBy: PropTypes.string,
  selectedItems: PropTypes.array,
  disabled: PropTypes.bool
};

CheckManagerComponent.defaultProps = {
  optionProp: 'id'
};

export default memo(CheckManagerComponent);
