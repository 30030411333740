import { useState, useCallback, useEffect, useMemo, Children, cloneElement, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import set from 'lodash/set';
import { useSnackbar } from 'notistack';
import useFormError from './useFormError';

export const parseValues = (data, fields) => {
  const payload = {};
  for (const field of fields.split('|')) {
    const [prop, type] = field.split(':');
    let value = get(data, prop);
    switch (type) {
      case 'integer':
        if (value) {
          value = Number(value);
        }
        break;
      case 'number':
        if (value) {
          value = parseFloat(value);
        }
        break;
      default:
        if (value === '') {
          value = null;
        }
        break;
    }
    if (value !== null || prop.indexOf('.') === -1) {
      set(payload, prop, value);
    }
  }
  return payload;
};

export default function useForm ({
  data,
  children,
  onSubmit,
  onClear,
  disabled: disabledForm,
  beforeSave,
  afterSave,
  onSuccessMessage,
  onChange,
  clearAfterSave
}) {
  const hasMount = useRef(false);
  const { state } = useLocation();
  const [currentData, setCurrentData] = useState({ ...state?.formData, ...data });
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const [responseErrors, setResponseErrors] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { isValid, hasError, setError } = useFormError();

  useEffect(() => {
    // evita que os inputs sejam renderizados duas vezes no init por alteração currentData e consequentemente do handleChange
    if (hasMount.current) {
      setCurrentData({ ...data });
    } else {
      hasMount.current = true;
    }
  }, [data]);

  const fields = useMemo(() => Children
    .toArray(children)
    .reduce((result, child) => {
      if (child?.props.prop) {
        const { type, prop } = child.props;
        result.push(`${prop}:${type}`);
      }
      return result;
    }, [])
    .join('|')
  , [children]);

  const handleSubmit = useCallback(async evt => {
    evt.preventDefault();
    if (!onSubmit) {
      return;
    }
    setIsLoading(true);
    try {
      let payload = parseValues(currentData, fields);
      if (beforeSave) {
        payload = await beforeSave(payload);
        if (!payload) {
          setIsLoading(false);
          return;
        }
      }
      const response = await onSubmit(payload);
      if (onSuccessMessage) {
        enqueueSnackbar(onSuccessMessage, { variant: 'success' });
      }
      if (response === false || (typeof afterSave === 'function' && afterSave(response) === false)) {
        return;
      }
      setIsDirty(false);
      setIsPristine(true);
      if (clearAfterSave) {
        setCurrentData({});
      }
    } catch (err) {
      setResponseErrors(err);
      enqueueSnackbar(err?.message, { variant: 'error' });
    }
    setIsLoading(false);
  }, [currentData, fields, beforeSave, onSubmit, onSuccessMessage, afterSave, clearAfterSave, enqueueSnackbar]);

  const handleClear = useCallback(() => {
    setCurrentData({});
    if (onClear) {
      onClear();
    }
  }, [onClear]);

  const handleChange = useCallback((prop, value) => {
    setIsDirty(true);
    setIsPristine(false);
    set(currentData, prop, value);
    if (onChange) {
      onChange(prop, value, currentData, setCurrentData);
    }
  }, [currentData, onChange]);

  const childrenWithValues = useMemo(
    () =>
      Children.map(children, child => {
        if (!child) {
          return child;
        }
        const { disabled: disabledField, prop, onChange: onChangeField } = child.props;
        const disabled = onSubmit === undefined || disabledForm || isLoading || (typeof disabledField === 'function' ? disabledField(currentData) : disabledField);
        const fieldProps = { data: currentData, disabled, setError };
        if (!prop) {
          return cloneElement(child, { ...fieldProps, onChange: onChangeField ?? handleChange });
        }
        const value = get(currentData, prop);
        return cloneElement(child, { ...fieldProps, value, onChange: handleChange });
      }),
    [children, onSubmit, disabledForm, isLoading, currentData, setError, handleChange]
  );

  const isSaveDisabled = useMemo(
    () => !isValid || disabledForm || (isPristine && !!currentData.id),
    [currentData.id, isPristine, isValid, disabledForm]
  );

  return {
    isLoading,
    currentData,
    isDirty,
    isPristine,
    isSaveDisabled,
    handleSubmit,
    handleChange,
    handleClear,
    responseErrors,
    isValid,
    hasError,
    childrenWithValues
  };
}
