import { NAMESPACE } from 'constant';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const { REACT_APP_DOMAIN: domain, NODE_ENV } = process.env;
const options = {
  domain,
  secure: NODE_ENV === 'production',
  sameSite: 'strict',
  path: '/'
};

export function getData (name) {
  return cookies.get(`${NAMESPACE}${name}`);
}

export function setData (name, value) {
  if (value) {
    cookies.set(`${NAMESPACE}${name}`, value, options);
  }
  return value;
}

export function clearData (name) {
  cookies.remove(`${NAMESPACE}${name}`, options);
}