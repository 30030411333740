import { createTheme } from '@material-ui/core/styles';
import { createGlobalStyle } from 'styled-components';
import { ptBR } from '@material-ui/core/locale';
import { grey, amber, green, red } from '@material-ui/core/colors';

export const COLORS = {
  primary: {
    main: ['#0099FF', '#40B3FF', '#7FCCFF', '#BFE5FF'],
    light: ['#7DD7FF', '#9EE1FF', '#BEEBFF', '#DEF5FF']
  },
  secondary: {
    main: ['#FF9933', '#FFB366', '#FFCC99', '#FFE5CC'],
    light: ['#FFC44D', '#FFDC8C', '#FFE7B2', '#FFF3D9']
  },
  success: {
    main: ['#39C44D', '#6BD37A', '#9CE1A6', '#CDF0D2'],
    light: ['#72ED84', '#95F2A3', '#B8F6C1', '#DCFAE0']
  },
  dark: '#1D1D1B'
};

export const GlobalStyle = createGlobalStyle`
  .textSecondary {
    color: ${grey[500]};
  }
  .textDisabled {
    color: ${grey[400]};
  }
  .textWarning {
    color: ${amber[800]};
  }
  .warning {
    background-color: ${amber[600]};
  }
  .textSuccess {
    color: ${green[700]};
  }
  .success {
    background-color: ${green[500]};
  }
  .textError {
    color: ${red[600]};
  }
  .error {
    background-color: ${red[500]};
  }
`;

export default createTheme({
  palette: {
    primary: {
      main: COLORS.primary.main[0]
    },
    secondary: {
      main: COLORS.dark
    },
    table: {
      header: {
        backgroundColor: grey[200]
      }
    },
    background: {
      aux: grey[200],
      auxHover: grey[300]
    }
  },
  typography: {
    fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(',')
  },
  overrides: {
    MuiBadge: {
      badge: {
        lineHeight: 1
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12
      }
    },
    MuiSkeleton:  {
      root: {
        display: 'inline-block'
      }
    }
  }
}, ptBR);
