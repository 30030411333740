import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { createGlobalStyle } from 'styled-components';

const progressSize = 6;
const fabSize = 56;
export const size = fabSize + progressSize * 2;

export const GlobalStyle = createGlobalStyle`
  body {
    padding-bottom: ${fabSize}px;
  }
`;

export default makeStyles(theme => ({
  button: {
    margin: progressSize
  },
  progress: {
    position: 'absolute',
    top: -progressSize,
    left: -progressSize,
    zIndex: 1
  },
  container: {
    zIndex: theme.zIndex.drawer,
    display: 'flex',
    position: 'fixed',
    bottom: theme.spacing(2) - progressSize,
    right: theme.spacing(2) - progressSize
  },
  success: {
    '&$progress': {
      color: green[600]
    },
    '&$button': {
      color: 'white',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700]
      },
      '&.Mui-disabled': {
        backgroundColor: green[300]
      }
    }
  },
  error: {
    '&$progress': {
      color: red[600]
    },
    '&$button': {
      color: 'white',
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700]
      },
      '&.Mui-disabled': {
        backgroundColor: red[300]
      }
    }
  }
}));
