import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  active: {},
  header: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.aux,
    '$active &': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  title: {
    flex: 1,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium
  },
  price: {
    fontSize: theme.typography.pxToRem(18),
    '& small': {
      opacity: 0.6
    }
  },
  properties: {
    flex: 1,
    listStyleType: 'none',
    padding: theme.spacing(1, 0),
    margin: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  property: {
    padding: theme.spacing(.5, 2),
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '& > * + *': {
      marginLeft: theme.spacing()
    }
  },
  button: {
    cursor: 'pointer'
  },
  success: {
    color: theme.palette.success.main
  },
  propertyName: {
    fontSize: theme.typography.pxToRem(14)
  },
  propertyValue: {
    flex: 1,
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));