import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import { Fab, Zoom } from '@material-ui/core';
import { ArrowUpward as NavigationIcon } from '@material-ui/icons';
import useStyles from './styles';
import debounce from 'lodash/debounce';

function ScrollTopComponent ({ title, threshold, delay }) {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const onScroll = debounce(() => {
      const visible = document.documentElement.scrollTop - threshold >= 0;
      setIsVisible(visible);
    }, delay);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [delay, threshold]);

  const handleClick = () => {
    setIsVisible(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={classes.root}>
      <Zoom in={isVisible}>
        <Fab variant={!!title ? 'extended' : 'default'} onClick={handleClick}>
          <NavigationIcon className={classnames(!!title && classes.extendedIcon)} />
          {title}
        </Fab>
      </Zoom>
    </div>
  );
}

ScrollTopComponent.propTypes = {
  threshold: PropTypes.number.isRequired,
  delay: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

ScrollTopComponent.defaultProps = {
  threshold: 10,
  delay: 100,
  title: 'Topo'
};

export default memo(ScrollTopComponent);
