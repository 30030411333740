import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    '& > *:empty': {
      padding: 0
    }
  },
  button: {
    marginTop: 10
  }
}));
