import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const getBackgroundColor = ({ color, variant }) => {
  if (variant === 'outlined') {
    return null;
  }
  switch (color) {
    case 'success':
      return green[500];
    case 'error':
      return red[500];
    default:
      return null;
  }
};

const getHoverBackgroundColor = ({ color, variant, onClick, copy }) => {
  if (variant === 'outlined') {
    return null;
  }
  switch (color) {
    case 'success':
      return green[onClick || copy ? 800 : 500];
    case 'error':
      return red[onClick || copy ? 800 : 500];
    default:
      return null;
  }
};

export default makeStyles(theme => ({
  root: {
    backgroundColor: getBackgroundColor,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor
    },
    '&:focus': {
      backgroundColor: getBackgroundColor
    },
    '& + &': {
      marginLeft: theme.spacing()
    }
  }
}));