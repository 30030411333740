import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, LinearProgress, InputAdornment } from '@material-ui/core';
import isNil from 'lodash/isNil';
import useStyles from './styles';
import { ERRORS } from 'constant';

function TextFieldComponent (props) {
  const {
    onChange,
    onBlur,
    prop,
    value,
    isLoading,
    prepend,
    append,
    maxLength,
    error,
    errors,
    timestamp,
    ...other
  } = props;
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [blur, setBlur] = useState(false);

  const handleChange = useCallback(evt => {
    setDirty(true);
    const val = evt?.target ? evt.target.value : evt;
    onChange(val);
  }, [onChange]);

  useEffect(() => {
    setDirty(false);
    setBlur(false);
  }, [timestamp]);

  const hasError = dirty && blur && !!error;

  const customProps = {
    name: prop,
    autoComplete: 'off',
    fullWidth: true,
    ...other,
    value: isNil(value) ? '' : value,
    onChange: handleChange,
    error: hasError,
    inputProps: {
      ...other.inputProps,
      maxLength
    },
    onBlur: evt => {
      setBlur(true);
      onBlur && onBlur(evt);
    }
  };

  if (typeof error === 'string' && hasError) {
    customProps.helperText = errors[error] ?? error;
  }

  if (prepend) {
    customProps.InputProps = {
      ...customProps.InputProps,
      startAdornment: (
        <InputAdornment position="start">
          {prepend}
        </InputAdornment>
      )
    };
  }
  if (append) {
    customProps.InputProps = {
      ...customProps.InputProps,
      endAdornment: (
        <InputAdornment position="end">
          {append}
        </InputAdornment>
      )
    };
  }

  delete customProps.fillWidth;

  return (
    <>
      <TextField {...customProps} />
      {isLoading && (
        <LinearProgress className={classes[error ? 'loadingError' : 'loading']} />
      )}
    </>
  );
}

TextFieldComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  prop: PropTypes.string,
  value: PropTypes.any,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  prepend: PropTypes.node,
  append: PropTypes.node,
  maxLength: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errors: PropTypes.object,
  timestamp: PropTypes.number,

  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  fillWidth: PropTypes.bool
};

TextFieldComponent.defaultProps = {
  errors: ERRORS
};

export default memo(TextFieldComponent);
