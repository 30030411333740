import { makeStyles } from '@material-ui/core/styles';

const OVERLAP_WIDTH = 80;

export default makeStyles(theme => ({
  root: {
    marginLeft: ({ index }) => -index * OVERLAP_WIDTH,
    [theme.breakpoints.up('md')]: {
      left: '125px !important'
    },
    [theme.breakpoints.up('lg')]: {
      left: '250px !important'
    },
    [theme.breakpoints.up('xl')]: {
      left: '500px !important'
    }
  },
  content: {
    padding: theme.spacing(2, 0)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  title: {
    paddingLeft: theme.spacing(),
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightMedium
  }
}));