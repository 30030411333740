import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& + *': {
      marginTop: theme.spacing(2)
    }
  }
}));