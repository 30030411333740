import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import { Box } from '@material-ui/core';
import useStyles from './styles';

const flex = ['flex-start', 'flex-end', 'center', 'space-around', 'space-between', 'space-evenly'];

const FlexComponent = forwardRef(function Component (props, ref) {
  const { children, className, column, spacing, align, justify, wrap, ...other } = props;
  const classes = useStyles({ column, spacing, align, justify, wrap });

  return (
    <Box
      className={classnames(classes.flex, className)}
      {...other}
      ref={ref}
    >
      {children}
    </Box>
  );
});

FlexComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  column: PropTypes.bool,
  spacing: PropTypes.number,
  align: PropTypes.oneOf(flex),
  justify: PropTypes.oneOf(flex),
  wrap: PropTypes.bool
};

FlexComponent.defaultProps = {
  spacing: 0
};

export default FlexComponent;
