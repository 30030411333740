import React from 'react';
import DynamicMaskFormat from './dynamic';

const FORMATS = [
  {
    size: 11,
    mask: '###.###.###-##'
  },
  {
    size: 14,
    mask: '##.###.###/####-##'
  }
];

export default function DocumentFormatComponent (props) {
  return <DynamicMaskFormat {...props} formats={FORMATS} />;
};
