import React, { createContext, useContext, useState, useCallback } from 'react';
import ConfirmationModal from './components/ConfirmationModal';

const defaults = {
  title: null,
  description: null,
  type: null,
  onConfirm: null,
  size: null,
  confirmLabel: 'Sim',
  cancelLabel: 'Não'
};

const ConfirmationContext = createContext();

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);

  if (context === undefined) {
    throw new Error('useConfirmation must be used within a ConfirmationProvider');
  }

  return context;
};

export function ConfirmationProvider (props) {
  const [current, setCurrent] = useState({ open: false });

  const confirm = useCallback(data => {
    setCurrent({ ...defaults, ...data, open: true });
  }, []);

  const handleClose = useCallback(() => {
    setCurrent(prev => ({ ...prev, open: false }));
  }, []);

  return (
    <>
      <ConfirmationContext.Provider {...props} value={confirm} />
      <ConfirmationModal {...current} onClose={handleClose} />
    </>
  );
};
