import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  numbers: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'flex'
  },
  number: {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 2),
    minWidth: '2.5em',
    textAlign: 'center',
    borderRadius: 4,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '& + &': {
      marginLeft: theme.spacing()
    }
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing()
  },
  click: {
    cursor: 'pointer'
  }
}));
