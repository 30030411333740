import React, { memo, Children, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Button } from '@material-ui/core';
import { Flex } from 'components';
import useStyles from './styles';
import useForm from 'hooks/useForm';

function ListViewFilters ({ data, onFilter, children, disabled }) {
  const onClear = useCallback(() => onFilter(), [onFilter]);

  const {
    handleSubmit,
    handleClear,
    childrenWithValues,
    hasError
  } = useForm({ data, onSubmit: onFilter, onClear, children, disabled });

  const classes = useStyles();

  if (!children?.length) {
    return null;
  }

  return (
    <Paper component="form" className={classes.listViewFilters} onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center">
        {Children.map(childrenWithValues, child => (
          <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
            {child}
          </Grid>
        ))}
        <Grid item>
          <Flex spacing={2}>
            <Button type="submit" variant="contained" color="secondary" disabled={disabled || hasError}>
              Pesquisar
            </Button>
            <Button variant="outlined" disabled={disabled} onClick={handleClear}>
              Limpar
            </Button>
          </Flex>
        </Grid>
      </Grid>
    </Paper>
  );
}

ListViewFilters.propTypes = {
  data: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool
};

ListViewFilters.defaultProps = {
  data: {}
};

export default memo(ListViewFilters);
