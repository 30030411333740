import React, { useState, useCallback, useMemo, memo, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Redirect } from 'react-router-dom';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import useStyles from './styles';
import { useConfirmation } from 'context';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledButton = styled(Button)(compose(spacing));
const StyledIconButton = styled(IconButton)(compose(spacing));

const FULL_CONFIRM_MESSAGE = 'Tem certeza de que deseja excluir';
const SHORT_CONFIRM_MESSAGE = 'Deseja excluir';

function RemoveButton ({
  onRemove,
  label,
  redirect,
  title,
  name,
  className,
  variant,
  children,
  disabled,
  size,
  type,
  description,
  ...other
}) {
  const [removed, setRemoved] = useState(false);
  const classes = useStyles({ variant });
  const confirm = useConfirmation();

  const handleClick = useCallback(() => {
    confirm({
      type,
      title: title ?? (name ? `${SHORT_CONFIRM_MESSAGE} ${name}?` : `${FULL_CONFIRM_MESSAGE}?`),
      size: 'xs',
      description,
      onConfirm: async () => {
        const response = await onRemove();
        if (response) {
          setRemoved(true);
        }
      }
    });
  }, [confirm, description, name, onRemove, title, type]);

  const content = useMemo(() => {
    const props = {
      ...other,
      onClick: handleClick,
      disabled: disabled || removed
    };
    if (children) {
      return cloneElement(children, props);
    }
    props.size = size;
    if (label) {
      return (
        <StyledButton {...props} variant={variant} className={classNames(classes.button, className)}>
          {label}
        </StyledButton>
      );
    }
    return (
      <Tooltip placement="top" title={disabled ? '' : 'Excluir'}>
        <StyledIconButton {...props} className={classNames(classes.iconButton, className)}>
          <DeleteIcon fontSize={size} />
        </StyledIconButton>
      </Tooltip>
    );
  }, [
    other,
    handleClick,
    disabled,
    removed,
    children,
    size,
    label,
    classes,
    className,
    variant
  ]);

  return (
    <>
      {removed && redirect && <Redirect to={redirect} />}
      {content}
    </>
  );
}

RemoveButton.propTypes = {
  onRemove: PropTypes.func.isRequired,
  label: PropTypes.string,
  redirect: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.element,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.node
};

RemoveButton.defaultProps = {
  type: 'Remover',
  variant: 'outlined'
};

export default memo(RemoveButton);
