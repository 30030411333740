import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

function fallbackCopyTextToClipboard (text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    return document.execCommand('copy');
  } catch (err) {
    throw err;
  } finally {
    document.body.removeChild(textArea);
  }
}

async function copyTextToClipboard (text) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return await navigator.clipboard.writeText(text);
}

export default function useClipboard () {
  const { enqueueSnackbar } = useSnackbar();
  const copy = useCallback(text => {
    try {
      copyTextToClipboard(text);
      enqueueSnackbar('Texto copiado para área de transferência.');
    } catch (err) {
      enqueueSnackbar(err || 'Erro ao copiar texto para área de transferência', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  return copy;
}
