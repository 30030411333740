import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import TextField from './TextField';
import { getProp } from 'helpers';

const hasError = (props, value) => {
  const { validate, required } = props;
  if (typeof validate === 'function') {
    const error = validate(value);
    if (error) {
      return error;
    }
  }
  if (required && !value) {
    return 'required';
  }
  return false;
};

function AutocompleteComponent (props) {
  const {
    onChange,
    onSearch,
    value,
    prop,
    options,
    disabled,
    required,
    getValue,
    getLabel,
    getOption,
    setError,
    error,
    groupBy,
    data,
    ...other
  } = props;
  const [typeValue, setTypeValue] = useState('');
  const [currentValue, setCurrentValue] = useState(null);
  const [currentOptions, setCurrentOptions] = useState(options || []);
  const [isLoading, setIsLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [timestamp, setTimestamp] = useState();
  useEffect(() => setTimestamp(new Date().getTime()), [data]);

  useEffect(() => setCurrentOptions(options || []), [options]);
  useEffect(() => setCurrentValue((
    value && currentOptions?.find(opt => getValue ? getProp(opt, getValue) === value : opt?.id === value?.id))
  ), [currentOptions, getValue, value, data]);

  const validationError = hasError(props, currentValue);
  useEffect(() => setError && setError(prop, validationError), [prop, setError, validationError]);

  delete other.validate;

  const handleChange = useCallback((_, val) => {
    const current = getProp(val, getValue);
    if (prop) {
      setCurrentValue(val);
      onChange(prop, current);
    } else {
      onChange(current);
    }
  }, [onChange, prop, getValue]);

  useEffect(() => {
    if (onSearch) {
      setSearchError(false);
      const timeoutId = setTimeout(async () => {
        setIsLoading(true);
        try {
          const response = await onSearch(typeValue);
          return setCurrentOptions(response);
        } catch (err) {
          setSearchError(true);
        } finally {
          setIsLoading(false);
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [onSearch, typeValue]);

  return (
    <Autocomplete
      options={currentOptions}
      loading={isLoading}
      id={`autocomplete_${prop}`}
      getOptionLabel={option => getProp(option, getLabel)}
      renderOption={option => getProp(option, getOption ?? getLabel)}
      disabled={disabled || (!onSearch && !options)}
      disableClearable={required}
      onChange={handleChange}
      groupBy={groupBy}
      value={currentOptions ? currentValue || null : null}
      renderInput={params => (
        <TextField
          {...params}
          {...other}
          timestamp={timestamp}
          required={required}
          value={typeValue}
          error={error || validationError || searchError}
          onChange={setTypeValue}
          isLoading={onSearch ? isLoading : options === undefined}
        />
      )}
    />
  );
}

AutocompleteComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  value: PropTypes.any,
  prop: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getOption: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  setError: PropTypes.func,
  error: PropTypes.bool,
  groupBy: PropTypes.func,
  data: PropTypes.object,

  validate: PropTypes.func
};

AutocompleteComponent.defaultProps = {
  getValue: 'id',
  getLabel: 'name'
};

export default memo(AutocompleteComponent);
