import React, { memo, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import NumberFormat, { findFormat } from '.';

function DynamicMaskFormatComponent ({ value, formats, ...other }) {
  const len = value?.length;
  const getFormat = useMemo(() => findFormat(formats), [formats]);
  const [format, setFormat] = useState(getFormat(len));

  useEffect(() => setFormat(getFormat(len)), [getFormat, len]);

  const handleKeyPress = useCallback(({ data }) => {
    const numbers = data.replace(/\D/g, '');
    setFormat(getFormat(len + numbers.length));
  }, [getFormat, len]);

  const handleKeyUp = useCallback(({ keyCode }) => {
    if (keyCode === 8) {
      setFormat(getFormat(len - 1));
    }
  }, [getFormat, len]);

  return (
    <NumberFormat
      {...other}
      value={value}
      format={format}
      mask="_"
      onBeforeInput={handleKeyPress}
      onKeyUp={handleKeyUp}
    />
  );
};

DynamicMaskFormatComponent.propTypes = {
  formats: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.number.isRequired,
      mask: PropTypes.string.isRequired
    })
  ),
  value: PropTypes.any
};

export default memo(DynamicMaskFormatComponent);
