import { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse, stringify } from 'qs';

const parseValues = data => {
  if (typeof data === 'object') {
    const parsed = {};
    for (const key in data) {
      parsed[key] = parseValues(data[key]);
    }
    return parsed;
  }
  const hasChars = new RegExp(/\D/);
  if (data && !hasChars.test(data)) {
    return Number(data);
  }
  return data;
};

const parseQueryString = (search, initialParams = {}) => {
  if (!search) {
    return initialParams;
  }
  const parsedParams = parse(search.substr(1));
  return parseValues(parsedParams);
};

export default function useQueryParams (initialValue, { disabled } = {}) {
  const { search } = useLocation();
  const previous = useRef();
  const [initialParams] = useState(initialValue);
  const [currentParams, setCurrentParams] = useState(parseQueryString(search, initialValue));
  const history = useHistory();

  useEffect(() => {
    if (!previous.current) {
      previous.current = initialParams;
    } else {
      setCurrentParams(parseQueryString(search, initialParams));
    }
  }, [initialParams, search, previous]);

  const setParams = useCallback((next, replace) => {
    const nextParams = typeof next === 'function' ? next({ ...previous.current }) : next;
    previous.current = nextParams;
    history[replace ? 'replace' : 'push']({ search: stringify(nextParams) });
  }, [history, previous]);

  return [currentParams, disabled ? setCurrentParams : setParams];
}
