import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2)
  },
  extendedIcon: {
    marginRight: theme.spacing()
  }
}));
