import React from 'react';
import { Alert } from '@material-ui/lab';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledAlert = styled(Alert)(compose(spacing));

export default function AlertComponent ({ ...props }) {
  return <StyledAlert {...props} />;
};
