import { getOnlyNumbers } from './misc';

function isValid (digits, correctDigitsLength, weights) {
  const cleanDigits = getOnlyNumbers(digits);
  if (cleanDigits.length !== correctDigitsLength || isAllTheSameDigits(cleanDigits)) {
    return false;
  }
  const digitsWithoutChecker = cleanDigits.substring(0, correctDigitsLength - 2);
  const digitsChecker = cleanDigits.substring(correctDigitsLength - 2, correctDigitsLength);
  const calculatedChecker = calcChecker(digitsWithoutChecker, weights);
  return digitsChecker === calculatedChecker;
}

function calcChecker (digits, weights) {
  const digitsLength = digits.length;
  const digitsLengthWithoutChecker = weights.length - 1;

  const sum = digits.split('').reduce((acc, digit, idx) => {
    return acc + +digit * weights[digitsLength - 1 - idx];
  }, 0);
  const sumDivisionRemainder = sum % 11;
  const checker = sumDivisionRemainder < 2 ? 0 : 11 - sumDivisionRemainder;

  if (digitsLength === digitsLengthWithoutChecker) {
    return calcChecker(`${digits}${checker}`, weights);
  }

  return `${digits[digitsLength - 1]}${checker}`;
}

export function isEmailValid (email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isAllTheSameDigits (digits) {
  return !digits.split('').find(digit => digit !== digits[0]);
}

export function isCpfValid (cpf) {
  const cpfLength = 11;
  const weights = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  return isValid(cpf, cpfLength, weights);
}

export function isCnpjValid (cnpj) {
  const cpfLength = 14;
  const weights = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6];
  return isValid(cnpj, cpfLength, weights);
}

export function isDocumentValid (string) {
  return isCpfValid(string) || isCnpjValid(string);
}