import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';

function SwitchComponent ({ onChange, prop, value, label, ...props }) {
  const [checked, setChecked] = useState(!!value);
  useEffect(() => setChecked(!!value), [value]);

  const handleChange = event => {
    const val = event.target.checked;
    if (prop) {
      setChecked(val);
      onChange(prop, val);
    } else {
      onChange(val);
    }
  };

  delete props.setError;

  return (
    <FormControl {...props}>
      <FormControlLabel
        label={label}
        checked={checked}
        control={(
          <Switch
            checked={checked}
            id={`switch_${prop}`}
            onChange={handleChange}
          />
        )}
      />
    </FormControl>
  );
}

SwitchComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  prop: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  setError: PropTypes.func
};

export default memo(SwitchComponent);
