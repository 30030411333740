import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { InlineForm } from 'components';
import uniqueId from 'lodash/uniqueId';

function DataArrayComponent ({ prop: parentProp, renderItem, onChange, value, required, setError, ...props }) {
  const [currentValues, setCurrentValues] = useState();

  const handleAdd = useCallback(payload => {
    setCurrentValues(prev => {
      const next = prev.concat({
        item: payload,
        key: uniqueId(`${parentProp}_`)
      });
      onChange(parentProp, next.map(data => data.item));
      return next;
    });
  }, [onChange, parentProp]);

  const handleRemove = item => () => {
    setCurrentValues(prev => {
      const next = prev.filter(data => data.item !== item);
      onChange(parentProp, next.map(data => data.item));
      return next;
    });
  };

  useEffect(() => setCurrentValues(value?.map(item => ({ item, key: item.id })) ?? []), [value]);
  useEffect(() => setError(parentProp, required && !currentValues?.length), [currentValues, parentProp, required, setError]);

  return (
    <>
      {currentValues?.map(({ item, key }, index) => (
        renderItem({ item, key, index, onRemove: handleRemove(item) })
      ))}
      <InlineForm
        confirmLabel="Adicionar"
        {...props}
        onSubmit={handleAdd}
        clearAfterSave
        renderAsDiv
      />
    </>
  );
}

DataArrayComponent.propTypes = {
  prop: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  required: PropTypes.bool,
  setError: PropTypes.func
};

export default memo(DataArrayComponent);
