import { lazy } from 'react';

import unit, { unitList } from './Unit/routes';

export * from './Unit/routes';

export const signIn = '/entrar';
export const signUp = '/cadastro';
export const forgotPassword = '/esqueci-senha';
export const changePassword = '/alterar-senha';
export const root = unitList;

const routes = [
  {
    key: 'signIn',
    path: signIn,
    isPublic: true,
    component: lazy(() => import('./SignIn'))
  },
  {
    key: 'signUp',
    path: signUp,
    isPublic: true,
    component: lazy(() => import('./SignUp'))
  },
  {
    key: 'forgotPassword',
    path: forgotPassword,
    isPublic: true,
    component: lazy(() => import('./ForgotPassword'))
  },
  {
    key: 'changePassword',
    path: changePassword,
    isPublic: true,
    component: lazy(() => import('./ResetPassword'))
  },
  ...unit,
  {
    key: 'notFound',
    component: lazy(() => import('./NotFound'))
  }
];

export default routes;