import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  group: {
    backgroundColor: theme.palette.action.selected,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing()
  },
  item: {
    cursor: 'pointer',
    borderTop: `1px solid ${theme.palette.divider}`,
    '& + $group': {
      marginTop: theme.spacing(2)
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  data: {
    fontSize: theme.typography.pxToRem(14)
  }
}));
