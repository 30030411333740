import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Breadcrumb, Flex, Grow, Alert } from 'components';
import useStyles from './styles';

export default function ContentComponent ({ children, header, label, errorMessage, ...other }) {
  const classes = useStyles();

  return (
    <Box p={3} {...other}>
      <Flex className={classes.header} spacing={2}>
        <Breadcrumb label={label} />
        <Grow />
        {header}
      </Flex>
      {!errorMessage ? children : (
        <Alert severity="error">{errorMessage}</Alert>
      )}
    </Box>
  );
}

ContentComponent.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  label: PropTypes.string,
  errorMessage: PropTypes.node
};
