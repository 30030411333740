import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  loading: {
    marginTop: -1,
    height: 1
  },
  loadingError: {
    marginTop: -2,
    height: 2
  },
  radioGroup: {
    flexDirection: 'row'
  }
});
