import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import { useWindow } from 'context';

export default function CenterComponent ({ children, force, height, ...other }) {
  const { displacement } = useWindow();
  const classes = useStyles({ height, force, displacement });

  return (
    <Box {...other} className={classes.root}>
      {children}
    </Box>
  );
}

CenterComponent.propTypes = {
  children: PropTypes.node.isRequired,
  force: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
