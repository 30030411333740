import { makeStyles } from '@material-ui/core/styles';
import logo_dark from 'assets/logo-nema_simbolo-cores_rgb_fundo_escuro.png';
import logo_light from 'assets/logo-nema_simbolo-cores_rgb_fundo_claro.png';
import { getLogoHeight } from 'constant';

const contentWidth = 520;
const logoWidth = 160;
const mobileLogoWidth = 120;

export default makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.paper
    }
  },
  container: {
    width: '100%',
    [theme.breakpoints.only('sm')]: {
      ...theme.shape,
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.background.paper,
      maxWidth: contentWidth - theme.spacing(6)
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  bg: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: contentWidth,
      backgroundColor: theme.palette.secondary.main,
      background: 'radial-gradient(circle, rgba(29,29,27,1) 25%, rgba(0,0,0,1) 85%)'
    }
  },
  logo: {
    width: mobileLogoWidth,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${logo_light})`,
    height: getLogoHeight(mobileLogoWidth),
    [theme.breakpoints.up('md')]: {
      width: logoWidth,
      height: getLogoHeight(logoWidth),
      backgroundImage: `url(${logo_dark})`,
      backgroundColor: theme.palette.secondary.main,
      backgroundBlendMode: 'hard-light'
    }
  },
  brand: {
    marginTop: theme.spacing(),
    fontWeight: 600,
    fontFamily: 'Titillium Web',
    fontSize: theme.typography.pxToRem(42),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(48),
      color: theme.palette.secondary.contrastText
    }
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
      width: contentWidth
    }
  },
  content: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    }
  }
}));
