import React, { Children, memo, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { TableRow, LinearProgress } from '@material-ui/core';
import { EmptyContent, TableCell } from 'components';
import useStyles from './styles';
import isEmpty from 'lodash/isEmpty';

function ListViewBody ({ children, data, loading, where, getRowStyle }) {
  const length = Children.count(children);
  const classes = useStyles();

  if (loading) {
    return (
      <TableRow>
        <TableCell colSpan={length}>
          <LinearProgress variant="query" />
        </TableCell>
      </TableRow>
    );
  }

  if (!data) {
    return <TableRow />;
  }

  if (!data.length) {
    const message = !isEmpty(where)
      ? 'Nenhum registro encontrado com os parâmetros informados.'
      : 'Nenhum registro encontrado.';
    return (
      <TableRow>
        <TableCell colSpan={length}>
          <EmptyContent text={message} onlyMessage />
        </TableCell>
      </TableRow>
    );
  }

  return data.map((item, index) => (
    <TableRow hover key={item.id || index} className={classes.listViewBodyRow} style={getRowStyle(item, index)}>
      {Children.map(children, child => {
        if (!child) {
          return null;
        }
        return cloneElement(child, { data: item, index });
      })}
    </TableRow>
  ));
}

ListViewBody.propTypes = {
  children: PropTypes.node.isRequired,
  getRowStyle: PropTypes.func.isRequired,
  where: PropTypes.object,
  data: PropTypes.array,
  loading: PropTypes.bool
};

ListViewBody.defaultProps = {
  getRowStyle: () => {}
};

export default memo(ListViewBody);
