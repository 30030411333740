import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function AddButtonComponent ({ path, label }) {
  return (
    <Button component={Link} to={path} color="primary" variant="contained">
      Adicionar {label}
    </Button>
  );
}

AddButtonComponent.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string
};
