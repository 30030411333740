import { makeStyles } from '@material-ui/core/styles';
import { green, blue, amber, red } from '@material-ui/core/colors';

const getColor = (shade, color, fallback = blue[shade]) => {
  switch (color) {
    case 'success':
      return green[shade];
    case 'warning':
      return amber[shade];
    case 'error':
      return red[shade];
    default:
      return fallback;
  }
};

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    position: 'relative',
    flexDirection: 'column',
    height: '100%'
  },
  progressBg: {
    position: 'absolute',
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    top: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundColor: ({ progressColor }) => getColor(100, progressColor)
  },
  progress: {
    height: ({ progress }) => `${progress}%`,
    width: 10,
    backgroundColor: ({ progressColor }) => getColor(400, progressColor)
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  row: {
    display: 'flex',
    flex: 1
  },
  label: {
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary
  },
  count: {
    flex: 1,
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightMedium
  },
  text: {
    fontSize: theme.typography.pxToRem(21),
    color: ({ color }) => getColor(700, color, null)
  }
}));
