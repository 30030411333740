import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  listViewFilters: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  listTable: {
    minWidth: 700
  },
  listViewBodyRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.paper
    }
  },
  listButtons: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5)
  },
  listViewPaginationActions: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  }
}));
