import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { useSnackbar } from 'notistack';

function CheckManagerItemComponent ({ value, onCreate, onRemove, item, disabled }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => setCurrentItem(item), [item]);

  const handleChange = async () => {
    setIsLoading(true);
    try {
      if (currentItem) {
        if (onRemove) {
          await onRemove(currentItem?.id);
          setCurrentItem();
        }
      } else {
        if (onCreate) {
          const response = await onCreate(value);
          setCurrentItem(response);
        }
      }
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const canChange = currentItem ? !!onRemove : !!onCreate;

  return (
    <Checkbox
      checked={!!currentItem}
      onChange={handleChange}
      disabled={isLoading || disabled || !canChange}
    />
  );
}

CheckManagerItemComponent.propTypes = {
  value: PropTypes.any.isRequired,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.any.isRequired
  }),
  disabled: PropTypes.bool
};

export default memo(CheckManagerItemComponent);
