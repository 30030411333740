import React from 'react';
import PropTypes from 'prop-types';
import useStyles, { GlobalStyle } from './styles';

export default function FabContainer ({ children }) {
  const classes = useStyles();

  if (!children) {
    return null;
  }

  return (
    <>
      <div className={classes.container}>
        {children}
      </div>
      <GlobalStyle />
    </>
  );
}

FabContainer.propTypes = {
  children: PropTypes.node
};
