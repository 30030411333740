import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function MarkdownComponent ({ content, ...other }) {
  if (!content) {
    return null;
  }

  return (
    <ReactMarkdown {...other} remarkPlugins={[remarkGfm]}>
      {content}
    </ReactMarkdown>
  );
}

MarkdownComponent.propTypes = {
  content: PropTypes.string
};

export default memo(MarkdownComponent);
