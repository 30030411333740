import { lazy } from 'react';

const root = '/unidades';

export const unitList = '/';
export const unitCreate = `${root}/adicionar`;
export const unitUpdate = (id = ':unitId') => `${root}/${id}/editar`;

const routes = [
  {
    key: 'unitList',
    path: unitList,
    policy: 'get:units',
    label: 'Unidades de atendimento',
    component: lazy(() => import('./list'))
  },
  {
    key: 'unitCreate',
    path: unitCreate,
    policy: 'post:units',
    label: 'Adicionar',
    component: lazy(() => import('./create'))
  },
  {
    key: 'unitUpdate',
    path: unitUpdate(),
    policy: 'get:unit',
    label: 'Detalhe',
    component: lazy(() => import('./update'))
  }
];

export default routes;