import React, { memo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Visibility as PasswordVisibleIcon, VisibilityOff as PasswordHiddenIcon } from '@material-ui/icons';
import Input from './Default';

function InputPasswordComponent (props) {
  const [showPassword, setShowPassword] = useState(false);
  const Icon = showPassword ? PasswordVisibleIcon : PasswordHiddenIcon;

  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      append={
        <IconButton onClick={() => setShowPassword(prev => !prev)}>
          <Icon fontSize="small" />
        </IconButton>
      }
    />
  );
}


export default memo(InputPasswordComponent);
