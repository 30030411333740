import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export default makeStyles(theme => ({
  button: {
    color: ({ variant }) => variant === 'contained' ? theme.palette.getContrastText(red[400]) : red[400],
    backgroundColor: ({ variant }) => variant === 'contained' ? red[400] : 'transparent',
    borderColor: ({ variant }) => variant === 'contained' ? 'transparent' : red[600],
    '&:hover': {
      backgroundColor: ({ variant }) => variant === 'contained' ? red[600] : red[50],
      borderColor: ({ variant }) => variant === 'contained' ? 'transparent' : red[700]
    }
  },
  iconButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: red[50]
    }
  }
}));
