import React, { memo, Children, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button as MuiButton } from '@material-ui/core';
import { Button, Fieldset, Switch, Checkbox, Flex } from 'components';
import { useForm } from 'hooks';
import pick from 'lodash/pick';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';
import useStyles from './styles';

const StyledDiv = styled.div(compose(spacing));
const StyledForm = styled.form(compose(spacing));

const defaultChildSizes = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 };
const sizeProps = Object.keys(defaultChildSizes);

function DataInlineFormComponent ({
  children,
  onSubmit,
  spacing,
  data,
  disabled,
  beforeSave,
  afterSave,
  confirmLabel,
  cancelLabel,
  onSuccessMessage,
  onCancel,
  onChange,
  clearAfterSave,
  renderAsDiv,
  fullWidth,
  submitProps,
  ...props
}) {
  const { handleSubmit, isSaveDisabled, isLoading, childrenWithValues } = useForm({
    children,
    onSubmit,
    data,
    disabled,
    beforeSave,
    afterSave,
    onSuccessMessage,
    onChange,
    clearAfterSave
  });
  const classes = useStyles();

  const Styled = renderAsDiv ? StyledDiv : StyledForm;

  if (!renderAsDiv) {
    props.onSubmit = handleSubmit;
    props.autoComplete = 'off';
    props.noValidate = true;
  }

  const _submitProps = useMemo(() => {
    const _props = {};
    if (fullWidth) {
      _props.size = 'large';
    }
    Object.assign(_props, submitProps);
    if (renderAsDiv) {
      _props.onClick = handleSubmit;
    } else {
      _props.type = 'submit';
    }
    return _props;
  }, [fullWidth, handleSubmit, renderAsDiv, submitProps]);

  return (
    <Styled {...props}>
      <Grid className={classes.container} container spacing={spacing} alignItems="flex-start">
        {Children.map(childrenWithValues, child => {
          if (fullWidth || child.type === Fieldset || child.props?.fullWidth) {
            return <Grid item xs={12}>{child}</Grid>;
          }
          if ([MuiButton, Switch, Checkbox].includes(child.type)) {
            return <Grid className={classes.button} item>{child}</Grid>;
          }
          const sizes = child.props.fillWidth ? { xs: true } : { ...defaultChildSizes, ...pick(child.props, sizeProps) };
          return (
            <Grid item {...sizes}>
              {child}
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Flex spacing={1} column={fullWidth}>
            {!!onSubmit && (
              <Button
                {..._submitProps}
                color="primary"
                variant="contained"
                loading={isLoading}
                disabled={isSaveDisabled}
                fullWidth={fullWidth}
              >
                {confirmLabel}
              </Button>
            )}
            {!!onCancel && (
              <Button onClick={onCancel} disabled={isLoading}>
                {cancelLabel}
              </Button>
            )}
          </Flex>
        </Grid>
      </Grid>
    </Styled>
  );
}

DataInlineFormComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  spacing: PropTypes.number,
  data: PropTypes.object,
  beforeSave: PropTypes.func,
  afterSave: PropTypes.func,
  disabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onSuccessMessage: PropTypes.string,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  clearAfterSave: PropTypes.bool,
  renderAsDiv: PropTypes.bool,
  fullWidth: PropTypes.bool,
  submitProps: PropTypes.object
};

DataInlineFormComponent.defaultProps = {
  spacing: 3,
  confirmLabel: 'Salvar',
  cancelLabel: 'Cancelar'
};

export default memo(DataInlineFormComponent);
