import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import useStyles from './styles';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledButton = styled(Button)(compose(spacing));
const SIZE = 24;

export default function LoadingButtonComponent ({ loading, disabled, children, loaderColor, ...props }) {
  const classes = useStyles({ size: SIZE });

  return (
    <StyledButton {...props} disabled={loading || disabled}>
      {children}
      {loading && (
        <CircularProgress
          size={SIZE}
          className={classes.progress}
          color={loaderColor}
        />
      )}
    </StyledButton>
  );
};

LoadingButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  loaderColor: PropTypes.string
};
