import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

export default withStyles(theme => ({
  head: {
    ...theme.palette.table.header
  },
  body: {
    color: 'inherit',
    fontWeight: 'inherit'
  },
  footer: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(20)
  }
}))(TableCell);
