import { byId } from 'helpers/misc';

export const GENDERS = [
  {
    id: 'female',
    name: 'Feminino'
  },
  {
    id: 'male',
    name: 'Masculino'
  }
];

export const GENDERS_BY_ID = byId(GENDERS);
export const getGenderName = gender => GENDERS_BY_ID[gender];
