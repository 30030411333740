import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import theme, { GlobalStyle } from 'theme';
import Pages from 'pages';
import Context from 'context';

export default function App () {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      <Context>
        <Router>
          <Pages />
        </Router>
      </Context>
    </ThemeProvider>
  );
}
