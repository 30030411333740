import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import { Paper, Typography, Box, Menu, MenuItem } from '@material-ui/core';
import get from 'lodash/get';
import { getProp } from 'helpers';
import useStyles from './styles';

function CardSelectorComponent ({
  onChange,
  getLabel,
  getValue,
  value,
  options,
  title,
  disabled,
  beforeChange,
  ...other
}) {
  const classes = useStyles();
  const [data, setData] = useState({});

  if (!options?.length) {
    return null;
  }

  const handleClick = item => evt => {
    if (disabled) {
      return;
    }
    const _options = !!beforeChange && beforeChange(item);
    if (!Array.isArray(_options)) {
      onChange(item);
    } else {
      setData({ item, anchorEl: evt.currentTarget, options: _options });
    }
  };

  const handleClose = () => {
    setData(prev => ({ ...prev, anchorEl: null }));
  };

  const handleClickOption = item => () => {
    handleClose();
    onChange(data.item, item.value);
  };

  return (
    <Box {...other}>
      {!!title && (
        <Typography className={classes.title}>
          {title}
        </Typography>
      )}
      <ul className={classes.list}>
        {options.map(item => (
          <Paper
            key={item.id}
            className={classnames(
              classes.card,
              value === get(item, getValue) && classes.active
            )}
            component="li"
            onClick={handleClick(item)}
          >
            <div className={classes.cardIcon}>
              {item.icon}
            </div>
            <Typography className={classes.cardTitle}>
              {getProp(item, getLabel)}
            </Typography>
          </Paper>
        ))}
      </ul>
      <Menu
        id="variations-menu"
        anchorEl={data.anchorEl}
        open={Boolean(data.anchorEl)}
        onClose={handleClose}
      >
        {data.options?.map(item => (
          <MenuItem
            key={item.label}
            onClick={handleClickOption(item)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

CardSelectorComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  getLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  getValue: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  beforeChange: PropTypes.func
};

CardSelectorComponent.defaultProps = {
  getLabel: 'name',
  getValue: 'id'
};

export default memo(CardSelectorComponent);
