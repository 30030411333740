import React from 'react';
import { Typography } from '@material-ui/core';
import { ExitToApp as SignOutIcon } from '@material-ui/icons';
import { AuthContainer, Alert, Button } from 'components';
import Content from './content';
import { useAuthAccess, useAuth } from 'context';


export default function AccessSelectorComponent () {
  const { errorMessage, isLoading } = useAuthAccess();
  const { signOut, currentUser, loading: { signOut: isLeaving } } = useAuth();

  return (
    <AuthContainer title={currentUser?.Profile?.name}>
      <Typography align="center" color="textSecondary">
        Escolha seu acesso
      </Typography>
      {!!errorMessage && <Alert severity="error" mt={2}>{errorMessage}</Alert>}
      <Content />
      <Button
        variant="outlined"
        color="secondary"
        disabled={isLoading}
        loading={isLeaving}
        startIcon={<SignOutIcon />}
        onClick={signOut}
        fullWidth
        size="large"
        mt={4}
      >
        Sair
      </Button>
    </AuthContainer>
  );
}