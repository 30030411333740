import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  paper: {
    overflow: 'hidden',
    '& > table > tbody > tr:last-child > td': {
      borderBottom: 'none'
    }
  }
});
