import { makeStyles } from '@material-ui/core/styles';

const getHeight = ({ force, height = '100%', displacement }) => {
  if (force) {
    return displacement ? `calc(100vh - ${displacement}px)` : '100vh';
  }
  return height;
};

export default makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: getHeight,
    width: '100%'
  }
});
