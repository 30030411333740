import React, { useState, useCallback, useContext, createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import routes from 'pages/routes';
import Api, { setToken } from 'providers';
import AccessSelector from './components/AccessSelector';
import { useAuth } from '.';
import castArray from 'lodash/castArray';
import { APPLICATION } from 'constant';

const DICTIONARY = {
  'get:units': '*',
  'get:unit': '*',
  'post:units': '*',
  'patch:unit': '*',
  'patch:unit/address': '*',
  'post:unit/phones': '*',
  'patch:unit/phone': '*',
  'delete:unit/phone': '*'
};

const PATHS = routes.reduce((paths, route) => {
  for (const p of castArray(route.path)) {
    paths.push([p, route.policy]);
  }
  return paths;
}, []);

const getPolicyByPathname = pathname => {
  const path = PATHS.find((([path]) => matchPath(pathname, { path, exact: true })));
  if (path) {
    return path[1];
  }
};

function useAccessProvider () {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [accessList, setAccessList] = useState();
  const [storedAccessId, setStoredAccessId] = useState();
  const [accessData, setAccessData] = useState();
  const [termsList, setTermsList] = useState();
  const { currentUser } = useAuth();

  const setAccess = useCallback(async accessId => {
    setStoredAccessId(accessId);
    if (!accessId) {
      return setAccessData();
    }
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const { data: { tokens, policies } } = await Api.post('/auth/accesses', { accessId });
      const access = accessList.find(acc => acc.id === accessId);
      setToken(tokens, access?.Role?.Application?.name);
      if (access?.Role?.Application?.url && access.Role.Application.name !== APPLICATION) {
        window.location.assign(access.Role.Application.url);
      } else {
        setAccessData({ id: accessId, policies });
        setIsLoading(false);
      }
    } catch (err) {
      if (err.data?.terms) {
        setTermsList(err.data.terms);
      }
      setAccessData();
      setErrorMessage(err?.message);
      setIsLoading(false);
    }
  }, [accessList]);

  const fetchAccesses = useCallback(async () => {
    setErrorMessage(null);
    try {
      const { data } = await Api.get('/auth/accesses');
      setAccessList(data);
    } catch (err) {
      setAccessList([]);
      setErrorMessage(err?.message);
    }
  }, []);

  const currentAccess = useMemo(
    () => accessList?.find(a => a.id === accessData?.id),
    [accessData, accessList]
  );
  const isPendingAccess = !!currentUser && !currentAccess;

  useEffect(() => {
    setAccessList();
    setAccessData();
    setTermsList();
    if (currentUser) {
      fetchAccesses();
    }
  }, [currentUser, fetchAccesses]);

  const hasAccess = useCallback((policies, content) => {
    const response = content ?? true;
    if (!policies) {
      return response;
    }
    let hasAccess = false;
    for (const policy of castArray(policies)) {
      const policyId = DICTIONARY[policy] || policy;
      if (policyId === '*' || accessData?.policies.includes(policyId)) {
        hasAccess = true;
        break;
      }
    }
    return hasAccess ? response : ( content ? null : false );
  }, [accessData]);

  const hasAccessToPathname = useCallback(pathname => (
    hasAccess(getPolicyByPathname(pathname))
  ), [hasAccess]);

  return {
    isLoading,
    errorMessage,
    accessList,
    termsList,
    storedAccessId,
    currentAccess,
    isPendingAccess,
    hasAccess,
    hasAccessToPathname,
    setAccess
  };
}

const AccessContext = createContext();

export const useAccess = () => {
  const context = useContext(AccessContext);

  if (context === undefined) {
    throw new Error('useAccess must be used within a AccessProvider');
  }

  return context;
};

export function AccessProvider ({ children }) {
  const { isPendingAccess, ...access } = useAccessProvider();
  return (
    <AccessContext.Provider value={access}>
      {isPendingAccess ? <AccessSelector /> : children}
    </AccessContext.Provider>
  );
}

AccessProvider.propTypes = {
  children: PropTypes.node.isRequired
};
