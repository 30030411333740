import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import { Typography, Tooltip } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import useStyles from './styles';

function PriceCardPropertyComponent ({ name, value, title, onClick }) {
  const classes = useStyles();

  return (
    <Tooltip placement="top" title={title}>
      <li
        className={classnames(classes.property, !!onClick && classes.button)}
        onClick={onClick}
      >
        <CheckIcon className={classes.success} fontSize="small" />
        <Typography className={classes.propertyName}>
          {name}
        </Typography>
        {!!value && (
          <Typography className={classes.propertyValue}>
            {value}
          </Typography>
        )}
      </li>
    </Tooltip>
  );
}

PriceCardPropertyComponent.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.node,
  title: PropTypes.string,
  onClick: PropTypes.func
};

PriceCardPropertyComponent.defaultProps = {
  title: ''
};

export default memo(PriceCardPropertyComponent);
