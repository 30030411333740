export const NAME = 'Nema';
export const APPLICATION = 'Core';

const { REACT_APP_ENVIRONMENT } = process.env;
export const NAMESPACE = `@NEMA_${REACT_APP_ENVIRONMENT}:`;

export const logoWidth = 236;
export const logoHeight = 195;
export const passwordMinLength = 6;

export const getLogoHeight = customWidth => logoHeight * customWidth / logoWidth;

export * from './bool';
export * from './errors';
export * from './gender';