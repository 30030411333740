import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Flex, Paper } from 'components';
import { toCurrency, times } from 'helpers';
import useStyles from './styles';

function PriceCardComponent ({
  name,
  value,
  children,
  append,
  loading,
  className,
  active,
  loadingSize,
  ...other
}) {
  const classes = useStyles();
  const empty = useMemo(() => times(loadingSize), [loadingSize]);

  return (
    <Paper
      elevation={2}
      className={classnames(classes.root, className, active && classes.active)}
      {...other}
    >
      <Flex className={classes.header}>
        <Typography className={classes.title}>
          {name}
        </Typography>
        <Typography className={classes.price}>
          <strong>{toCurrency(value)}</strong>
          {!!append && <small>{append}</small>}
        </Typography>
      </Flex>
      <ul className={classes.properties}>
        {loading && empty.map(index => (
          <li className={classes.property} key={index}>
            <Typography className={classes.propertyName}>
              <Skeleton width={80} />
            </Typography>
            <Typography className={classes.propertyValue}>
              <Skeleton width={110} />
            </Typography>
          </li>
        ))}
        {children}
      </ul>
    </Paper>
  );
}

PriceCardComponent.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  children: PropTypes.node,
  append: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  active: PropTypes.bool,
  loadingSize: PropTypes.number
};

PriceCardComponent.defaultProps = {
  loadingSize: 3
};

export default memo(PriceCardComponent);
