import React, { useState, useEffect, useMemo } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton
} from '@material-ui/core';
import { Visibility as ShowIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Paper, Button } from 'components';
import useStyles from './styles';
import { useAuthAccess, useAuth } from 'context';
import { useSnackbar } from 'notistack';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

export default function AccessSelectorComponent () {
  const { acceptTerm, loading: { signOut: isLeaving } } = useAuth();
  const { accessList, storedAccessId, termsList, setAccess, isLoading } = useAuthAccess();
  const [currentTerm, setCurrentTerm] = useState({ open: false });
  const [accepted, setAccepted] = useState([]);
  const [accepting, setAccepting] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const groupedList = useMemo(() => groupBy(accessList, 'Role.applicationId'), [accessList]);

  const handleClose = () => setCurrentTerm(prev => ({ ...prev, open: false }));

  const handleAccept = termId => async () => {
    setAccepting(prev => prev.concat(termId));
    try {
      await acceptTerm(termId);
      setAccepted(prev => prev.concat(termId));
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
    } finally {
      setAccepting(prev => prev.filter(id => id !== termId));
    }
  };

  const handleOpen = term => () => {
    setCurrentTerm({ ...term, open: true });
  };

  const handleClickAccess = accessId => () => setAccess(accessId);

  useEffect(() => {
    if (storedAccessId && termsList?.length === accepted.length) {
      setAccess(storedAccessId);
    }
  }, [accepted.length, setAccess, storedAccessId, termsList?.length]);


  if (termsList) {
    const { id: currentTermId, open, name, content } = currentTerm;
    const hasAccepted = accepted.includes(currentTermId);
    const isAccepting = accepting.includes(currentTermId);

    return (
      <>
        <List>
          {termsList.map(term => (
            <ListItem key={term.id}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={accepted.includes(term.id)}
                  disabled={accepted.includes(term.id) || accepting.includes(term.id)}
                  onClick={handleAccept(term.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `term_${term.id}` }}
                />
              </ListItemIcon>
              <ListItemText
                id={`term_${term.id}`}
                primary={term.name}
                secondary={term.description}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="content" onClick={handleOpen(term)}>
                  <ShowIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="terms-dialog-title"
          aria-describedby="terms-dialog-description"
        >
          <DialogTitle id="terms-dialog-title">
            {name}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="terms-dialog-description">
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={isAccepting}>
              {hasAccepted ? 'Fechar' : 'Não aceito'}
            </Button>
            {!hasAccepted && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAccept(currentTermId)}
                disabled={hasAccepted}
                loading={isAccepting}
              >
                Aceito
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }

  if (!accessList) {
    return (
      <Paper mt={3} elevation={2} >
        <List disablePadding>
          <ListItem className={classes.header}>
            <ListItemText
              primary={<Skeleton width={70} />}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Skeleton width={150} />}
              secondary={<Skeleton width={180} />}
            />
          </ListItem>
        </List>
      </Paper>
    );
  }

  return map(groupedList, (list, applicationId) => (
    <Paper key={applicationId} mt={3} elevation={2} overflow="hidden">
      <List disablePadding>
        <ListItem className={classes.header}>
          <ListItemText
            primary={list[0].Role?.Application?.name}
          />
        </ListItem>
        {list?.map(access => (
          <ListItem
            key={access.id}
            onClick={handleClickAccess(access.id)}
            disabled={isLeaving || isLoading}
            className={classes.item}
            button
          >
            <ListItemText
              primary={access.Role?.name}
              secondary={access.PaymentSeller?.businessName}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  ));
}