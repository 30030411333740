import { useState, useCallback } from 'react';
import axios from 'axios';
import get from 'lodash/get';

export default function useAddress () {
  const [isLoading, setIsLoading] = useState(false);

  const getAddressFromPostalCode = useCallback(async postalCode => {
    if (!postalCode || postalCode.length !== 8) {
      return null;
    }
    setIsLoading(true);
    try {
      const { data } = await axios.get(`https://viacep.com.br/ws/${postalCode}/json`);
      const { logradouro: street, bairro: neighborhood, localidade: city, uf: state, erro } = data;
      if (!erro) {
        return { street, neighborhood, city, state, postalCode };
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getLatLngFromAddress = useCallback(async address => {
    if (typeof address !== 'object' || !address.street) {
      throw new Error('Endereço inválido');
    }

    setIsLoading(true);
    try {
      const errorMessage = 'Localização não encontrada';
      const { street, number, neighborhood, city, state } = address;
      const string = [street, number, neighborhood, city, state].filter(Boolean).join(', ');
      const geocoder = new window.google.maps.Geocoder();
      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: string }, results => {
          const location = get(results, '[0].geometry.location');
          if (location) {
            resolve(location.toJSON());
          } else {
            reject({ error: { message: errorMessage } });
          }
        });
      });
    } catch (err) {
      throw new Error(err?.message || 'Localização não encontrada');
    }
    finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, getAddressFromPostalCode, getLatLngFromAddress };
}
