import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useLink } from 'hooks';

const PolicyNavLinkComponent = forwardRef(function Component (props, ref) {
  return <NavLink {...useLink(props)} ref={ref} />;
});

PolicyNavLinkComponent.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })])
};

export default memo(PolicyNavLinkComponent);
