import React, { memo, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { TableCell } from 'components';
import useStyles from './styles';

function ListButtons ({ children, data, index, label }) {
  const classes = useStyles();

  return (
    <TableCell align={label ? 'center' : 'right'} className={classes.listButtons}>
      <Box displayPrint="none">
        {Children.map(children, child => cloneElement(child, { data, index }))}
      </Box>
    </TableCell>
  );
}

ListButtons.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object,
  index: PropTypes.number,
  label: PropTypes.string
};

export default memo(ListButtons);
