import React, { useState, useContext, createContext, useCallback, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';

const WindowContext = createContext();

export const useWindow = () => {
  const context = useContext(WindowContext);

  if (context === undefined) {
    throw new Error('useCurrentWindow must be used within a WindowProvider');
  }

  return context;
};

export function WindowProvider (props) {
  const [displacements, setDisplacements] = useState([]);

  const addDisplacement = useCallback(value => {
    const id = uniqueId('displace_');
    setDisplacements(prev => prev.concat({ id, value }));
    return () => setDisplacements(prev => prev.filter(dis => dis.id !== id));
  }, []);

  const displacement = useMemo(() => (
    displacements.reduce((total, dis) => total += dis.value, 0)
  ), [displacements]);

  return <WindowContext.Provider {...props} value={{ addDisplacement, displacement }} />;
}
