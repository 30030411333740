import React, { memo, useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import TextField from './TextField';
import { useSnackbar } from 'notistack';
import { getProp } from 'helpers';
import isNil from 'lodash/isNil';

const hasError = (options, props, value) => {
  const { validate, required, getValue } = props;
  if (typeof validate === 'function') {
    const error = validate(value);
    if (error) {
      return error;
    }
  }
  if (required && (isNil(value) || value === '')) {
    return 'required';
  }
  // valida se o valor do select está na lista de opções
  if (value) {
    if (Array.isArray(value) && value.length) {
      return false;
    }
    if (options && !options?.find((item, index) => getProp(item, getValue, index) == value)) {
      return 'invalid_option';
    }
  }
  return false;
};

function SelectComponent (props) {
  const {
    onChange,
    prop,
    value,
    options,
    isLoading: isLoadingExternal,
    getLabel,
    getValue,
    required,
    emptyLabel,
    error,
    setError,
    data,
    ...other
  } = props;
  const [currentValue, setCurrentValue] = useState(value);
  const [currentOptions, setCurrentOptions] = useState();
  const [optionError, setOptionError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [timestamp, setTimestamp] = useState();
  useEffect(() => setTimestamp(new Date().getTime()), [data]);

  const fetchOptions = useCallback(async () => {
    setOptionError(null);
    if (Array.isArray(options)) {
      setCurrentOptions(options);
    } else {
      setCurrentOptions();
      if (typeof options === 'function') {
        setIsLoading(true);
        try {
          setCurrentOptions(await options());
        } catch (err) {
          enqueueSnackbar(err?.message, { variant: 'error' });
          setOptionError(true);
        } finally {
          setIsLoading(false);
        }
      }
    }
  }, [enqueueSnackbar, options]);

  useEffect(() => setCurrentValue(value), [value, data]);
  useEffect(() => fetchOptions(), [fetchOptions]);

  const optionList = useMemo(() => {
    if (!Array.isArray(currentOptions)) {
      return (
        <MenuItem disabled>
          Sem opções disponíveis
        </MenuItem>
      );
    }
    const list = currentOptions.map((item, index) => {
      const val = getProp(item, getValue, index);
      return (
        <MenuItem key={val} value={val}>
          {getProp(item, getLabel)}
        </MenuItem>
      );
    });
    if (!required) {
      list.unshift(
        <MenuItem key="empty" value="">
          <em>{emptyLabel}</em>
        </MenuItem>
      );
    }
    return list;
  }, [currentOptions, required, getValue, getLabel, emptyLabel]);

  const validationError = hasError(currentOptions, props, currentValue);
  useEffect(() => setError && setError(prop, validationError), [prop, setError, validationError]);

  const handleChange = useCallback(val => {
    const _value = val === '' ? undefined : val;
    if (prop) {
      setCurrentValue(_value);
      onChange(prop, _value);
    } else {
      onChange(_value);
    }
  }, [onChange, prop]);

  const customProps = {
    ...other,
    required,
    onChange: handleChange,
    value: currentOptions ? currentValue : '',
    error: error || validationError || optionError,
    isLoading: isLoading || isLoadingExternal,
    timestamp
  };
  delete customProps.validate;

  return (
    <TextField {...customProps} select>
      {optionList}
    </TextField>
  );
}

SelectComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  prop: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  isLoading: PropTypes.bool,
  getLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  required: PropTypes.bool,
  emptyLabel: PropTypes.string,
  error: PropTypes.bool,
  setError: PropTypes.func,
  data: PropTypes.object,

  validate: PropTypes.func
};

SelectComponent.defaultProps = {
  getValue: 'id',
  getLabel: 'name',
  emptyLabel: 'Nenhum'
};

export default memo(SelectComponent);
