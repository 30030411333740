import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import { Save as SaveIcon, Clear as CancelIcon } from '@material-ui/icons';
import { Flex, IconButton } from 'components';
import { useForm } from 'hooks';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const Styled = styled.form(compose(spacing));

function PopupFormComponent ({ children, data, onSubmit, onClose, anchorEl, onSuccessMessage, ...others }) {
  const onSubmitModal = useCallback(payload => onSubmit(payload).then(onClose), [onSubmit, onClose]);

  const { handleSubmit, isSaveDisabled, isLoading, childrenWithValues } = useForm({
    data,
    children,
    onSubmit: onSubmitModal,
    onSuccessMessage,
    clearAfterSave: true
  });

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
    >
      <Styled py={1} px={2} {...others} onSubmit={handleSubmit} noValidate autoComplete="off">
        <Flex spacing={2}>
          {childrenWithValues}
          {!!onSubmit && (
            <IconButton disabled={isSaveDisabled} loading={isLoading} type="submit">
              <SaveIcon />
            </IconButton>
          )}
          <IconButton disabled={isLoading} onClick={onClose}>
            <CancelIcon />
          </IconButton>
        </Flex>
      </Styled>
    </Popover>
  );
}

PopupFormComponent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  anchorEl: PropTypes.object,
  onSuccessMessage: PropTypes.string
};

export default memo(PopupFormComponent);
