import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  breadcrumbs: {
    listStyleType: 'none',
    minHeight: 36,
    margin: 0,
    padding: 0,
    display: 'flex',
    '& > li': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      '&:hover > a': {
        color: theme.palette.text.primary
      }
    }
  },
  icon: {
    marginRight: theme.spacing(),
    display: 'block'
  },
  text: {
    marginRight: theme.spacing(),
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1,
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: theme.palette.secondary.light
    }
  },
  current: {
    marginRight: theme.spacing(),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1
  }
}));