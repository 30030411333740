import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.action.selected,
    textTransform: 'uppercase',
    '& .MuiListItemText-primary': {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary
    }
  },
  item: {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));
