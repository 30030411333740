import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

export default function GrowComponent ({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.grow}>
      {children}
    </div>
  );
}

GrowComponent.propTypes = {
  children: PropTypes.node
};
