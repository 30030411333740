import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { ExitToApp as SignOutIcon, Work as RoleIcon } from '@material-ui/icons';
import { IconButton, Flex, Grow } from 'components';
import { NAME } from 'constant';
import { useAuth, useAuthAccess, useWindow } from 'context';
import useStyles from './styles';

function AppBarComponent (props) {
  const classes = useStyles();
  const { currentUser, signOut, loading: { signOut: isLoading } } = useAuth();
  const { setAccess } = useAuthAccess();
  const { addDisplacement } = useWindow();

  useEffect(() => currentUser && addDisplacement(64), [addDisplacement, currentUser]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <AppBar color="secondary" {...props}>
        <Toolbar>
          <div className={classes.logo} />
          <Typography className={classes.brandName}>
            {NAME}
          </Typography>
          <Grow />
          <Flex align="center" column mx={3}>
            <Typography className={classes.userInfo}>
              {currentUser.Profile?.name}
            </Typography>
          </Flex>
          <IconButton
            onClick={() => setAccess()}
            label="Trocar Papel"
            placement="bottom"
            color="inherit"
          >
            <RoleIcon />
          </IconButton>
          <IconButton
            onClick={() => signOut()}
            loading={isLoading}
            label="Sair"
            placement="bottom"
            loaderColor="secondary"
            color="inherit"
          >
            <SignOutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}

AppBarComponent.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.element
    })
  )
};

export default memo(AppBarComponent);
