import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit as EditIcon, Visibility as ShowIcon, ArrowForwardIos as SelectIcon } from '@material-ui/icons';
import { RemoveButton, Link } from 'components';

const ICON = {
  edit: <EditIcon />,
  show: <ShowIcon />,
  select: <SelectIcon />
};

const TITLE = {
  edit: 'Editar',
  show: 'Visualizar',
  select: 'Selecionar'
};

function ListButton ({ data, index, path, href, variant, onClick, title, icon: Icon, disabled }) {
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  if (!data) {
    return null;
  }
  const isDisabled = isLoading || (typeof disabled === 'function' ? disabled(data, index): disabled);
  if (variant === 'remove') {
    return (
      <RemoveButton
        disabled={isDisabled}
        name={data.name}
        onRemove={() => onClick(data.id, data, index)}
      />
    );
  }
  const props = { disabled: isDisabled };
  if (path) {
    props.component = Link;
    props.to = { pathname: path(data.id), state: { search } };
  } else if (href) {
    props.component = 'a';
    props.href = href(data);
    props.target = 'blank';
  }
  if (onClick) {
    props.onClick = async () => {
      setIsLoading(true);
      let response;
      try {
        response = await onClick(data, index);
      } finally {
        if (response !== false) {
          setIsLoading(false);
        }
      }
    };
  }
  const tooltip = isDisabled ? '' : title ?? TITLE[variant];
  return (
    <Tooltip placement="top" title={tooltip}>
      <IconButton {...props}>
        {Icon ? <Icon /> : ICON[variant]}
      </IconButton>
    </Tooltip>
  );
}

ListButton.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  path: PropTypes.func,
  href: PropTypes.func,
  variant: PropTypes.oneOf(['edit', 'show', 'remove', 'select']),
  onClick: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.object,
  disabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default memo(ListButton);
