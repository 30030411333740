import axios from 'axios';
import { getData, setData, clearData } from 'helpers/storage';
import { signIn } from 'pages/routes';

const getTokenByType = (data, type) => {
  const accessToken = data?.find(i => i.type === type);
  return accessToken?.token;
};

const NAMESPACE = 'TOKEN';

export const getToken = (type = 'token') => {
  const data = getData(NAMESPACE);
  if (data) {
    return getTokenByType(data, type);
  }
};
export const setToken = (value, application) => {
  const key = application ? application + ':' : '';
  return setData(key + NAMESPACE, value);
};
export const clearToken = () => clearData(NAMESPACE);

export const parseError = Error => {
  const { response, message } = Error;
  const err = { message };
  if (response) {
    const { status, data } = response;
    if (status === 401 && window.location.pathname !== signIn) {
      return window.location.assign(signIn);
    }
    const { error, ...rest } = data;
    Object.assign(err, rest);
    if (Array.isArray(error)) {
      err.message = error.join(', ');
    } else if (typeof error === 'string') {
      err.message = error;
    }
  }
  return Promise.reject(err);
};

const Provider = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

Provider.interceptors.request.use(async config => {
  config.headers.Authorization = getToken();
  return config;
});

Provider.interceptors.response.use(
  response => response.data,
  parseError
);

export default Provider;