import { useMemo } from 'react';
import { useAuthAccess } from 'context';

export default function useLink ({ to, ...rest }) {
  const { hasAccessToPathname } = useAuthAccess();

  const pathname = typeof to === 'object' ? to.pathname : to;
  const hasAccess = useMemo(() => hasAccessToPathname(pathname), [hasAccessToPathname, pathname]);

  return useMemo(() => {
    const props = { ...rest, to };
    if (!hasAccess) {
      props.style = { display: 'none' };
    }
    return props;
  }, [hasAccess, rest, to]);
}