import React, { memo, useMemo, useState }  from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import { Box, Typography, Popover, IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { TextField } from 'components';
import { times } from 'helpers';
import useStyles from './styles';

function NumberPicker ({ onChange, start, length, value, max, label, emptyLabel, ...other }) {
  const last = max ? Math.min(max + 1, length) : length;
  const numbers = useMemo(() => times(last, start), [last, start]);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    setAnchorEl(null);
    onChange(Number(inputValue));
    setInputValue('');
  };

  return (
    <Box {...other}>
      {!!label && (
        <Typography className={classes.label}>
          {label}
        </Typography>
      )}
      <ul className={classes.numbers}>
        {numbers.map(number => (
          <li
            key={number}
            onClick={() => onChange(number)}
            className={classnames(
              classes.number,
              value === number && classes.selected,
              value !== number && classes.click
            )}
          >
            {emptyLabel && !number ? emptyLabel : number}
          </li>
        ))}
        {(!max || length < max) && (
          <li
            className={classnames(
              classes.number,
              classes.click,
              value > length && classes.selected
            )}
            onClick={handleOpen}
          >
            {value > length ? value : '+'}
          </li>
        )}
      </ul>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <form onSubmit={handleSubmit}>
          <TextField
            type="integer"
            value={inputValue}
            variant="outlined"
            onChange={setInputValue}
            append={
              <IconButton type="submit">
                <CheckIcon />
              </IconButton>
            }
            autoFocus
          />
        </form>
      </Popover>
    </Box>
  );
}

NumberPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  start: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  value: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  emptyLabel: PropTypes.string
};

NumberPicker.defaultProps = {
  length: 8,
  start: 1
};

export default memo(NumberPicker);
