import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Fab, Zoom, CircularProgress, Tooltip } from '@material-ui/core';
import { Link } from 'components';
import useStyles, { size } from './styles';

export default function FabButton ({ onClick, label, children, to, disabled, loading, color, ...other }) {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleClick = useCallback(async event => {
    if (typeof onClick === 'function') {
      setIsLoading(true);
      try {
        if (await onClick(event) !== false) {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }, [onClick]);

  const props = { ...other };
  if (to) {
    props.to = to;
    props.component = Link;
  }

  switch (color) {
    case 'success':
    case 'error':
      props.color = 'default';
      break;
    default:
      props.color = color;
      break;
  }

  return (
    <Tooltip title={label} placement="top">
      <Zoom in>
        <div>
          <Fab
            {...props}
            className={classNames(classes.button, classes[color])}
            onClick={handleClick}
            disabled={disabled || loading || isLoading}
            aria-label={label}
          >
            {children}
            {(loading || isLoading) && (
              <CircularProgress size={size} className={classNames(classes.progress, classes[color])} />
            )}
          </Fab>
        </div>
      </Zoom>
    </Tooltip>
  );
}

FabButton.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.any,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string
};
