import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Chip, Tooltip, CircularProgress } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import useStyles from './styles';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledChip = styled(Chip)(compose(spacing));

function ChipComponent ({ loading, title, placement, ...props }) {
  const classes = useStyles(props);
  const customProps = { ...props };

  switch (props.color) {
    case 'success':
      customProps.color = 'primary';
      break;
    case 'error':
      customProps.color = 'secondary';
      customProps.icon = <ErrorIcon />;
      break;
  }

  if (loading) {
    customProps.icon = <CircularProgress size={20} color="secondary" />;
    delete customProps.onClick;
  }

  return (
    <Tooltip title={title || ''} placement={placement}>
      <StyledChip className={classes.root} {...customProps} />
    </Tooltip>
  );
};

ChipComponent.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  placement: PropTypes.string,

  color: PropTypes.string,
  onClick: PropTypes.func
};

ChipComponent.defaultProps = {
  placement: 'top'
};

export default memo(ChipComponent);
