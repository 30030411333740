import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  },
  list: {
    display: 'flex',
    padding: 0,
    margin: 0,
    listStyleType: 'none'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    cursor: 'pointer',
    minWidth: 180,
    height: 130,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  cardIcon: {
    flex: 1,
    marginBottom: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(42)
  },
  cardTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightMedium
  }
}));
