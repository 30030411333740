import React, { useState, useCallback, useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import Api, { getToken, setToken, clearToken } from 'providers';

function useAuthProvider () {
  const [loading, setLoading] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [errors, setErrors] = useState({});
  const [userInfo, setUserInfo] = useState();
  const [terms, setTerms] = useState();

  const me = useCallback(async () => {
    const { data: user } = await Api.get('/me');
    setCurrentUser(user);
    return user;
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    setLoading(prev => ({ ...prev, signIn: true }));
    setErrors(prev => ({ ...prev, signIn: null }));
    try {
      const { data: { tokens } } = await Api.post('/login', { email, password });
      setToken(tokens);
      return await me();
    } catch (err) {
      setErrors(prev => ({ ...prev, signIn: err?.message }));
    } finally {
      setLoading(prev => ({ ...prev, signIn: false }));
    }
  }, [me]);

  const signUp = useCallback(async (userId, token, payload) => {
    setLoading(prev => ({ ...prev, signUp: true }));
    setErrors(prev => ({ ...prev, signUp: null }));
    try {
      await Api.patch(`users/${userId}/${token}/invite`, payload);
      return await signIn(payload);
    } catch (err) {
      setErrors(prev => ({ ...prev, signUp: err?.message }));
    } finally {
      setLoading(prev => ({ ...prev, signUp: false }));
    }
  }, [signIn]);

  const fetchUserInfo = useCallback(async (userId, token) => {
    setLoading(prev => ({ ...prev, fetchUserInfo: true }));
    setErrors(prev => ({ ...prev, fetchUserInfo: null }));
    try {
      const { data } = await Api.get(`users/${userId}/${token}`);
      setUserInfo(data);
      return data;
    } catch (err) {
      setErrors(prev => ({ ...prev, fetchUserInfo: err?.message }));
    } finally {
      setLoading(prev => ({ ...prev, fetchUserInfo: false }));
    }
  }, []);

  const fetchTerms = useCallback(async userId => {
    setLoading(prev => ({ ...prev, fetchTerms: true }));
    setErrors(prev => ({ ...prev, fetchTerms: null }));
    try {
      const { data } = await Api.get(`users/${userId}/terms`);
      setTerms(data);
      return data;
    } catch (err) {
      setErrors(prev => ({ ...prev, fetchTerms: err?.message }));
    } finally {
      setLoading(prev => ({ ...prev, fetchTerms: false }));
    }
  }, []);

  const resetPassword = useCallback(async email => {
    setLoading(prev => ({ ...prev, resetPassword: true }));
    setErrors(prev => ({ ...prev, resetPassword: null }));
    try {
      const { data } = await Api.post('/recovery', { email });
      return data;
    } catch (err) {
      setErrors(prev => ({ ...prev, resetPassword: err?.message }));
    } finally {
      setLoading(prev => ({ ...prev, resetPassword: false }));
    }
  }, []);

  const changePassword = useCallback(async ({ password, token }) => {
    setLoading(prev => ({ ...prev, changePassword: true }));
    setErrors(prev => ({ ...prev, changePassword: null }));
    try {
      const { data } = await Api.patch(`/recovery?token=${token}`, { password });
      return await signIn({ ...data, password });
    } catch (err) {
      setErrors(prev => ({ ...prev, changePassword: err?.message }));
    } finally {
      setLoading(prev => ({ ...prev, changePassword: false }));
    }
  }, [signIn]);

  const signOut = useCallback(async () => {
    clearToken();
    setCurrentUser(null);
  }, []);

  const acceptTerm = useCallback(async termId => {
    setLoading(prev => ({ ...prev, acceptTerm: true }));
    try {
      const { data } = await Api.post('/users/acceptTerms', { termId });
      return data;
    } finally {
      setLoading(prev => ({ ...prev, acceptTerm: false }));
    }
  }, []);

  useEffect(() => {
    const checkLogin = async () => {
      try {
        if (getToken()) {
          await me();
        } else {
          setCurrentUser(null);
        }
      } catch (err) {
        clearToken();
        setCurrentUser(null);
      }
    };
    checkLogin();
  }, [me]);

  return {
    loading,
    errors,
    currentUser,
    userInfo,
    terms,
    signIn,
    signOut,
    signUp,
    fetchUserInfo,
    fetchTerms,
    acceptTerm,
    resetPassword,
    changePassword
  };
}

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export function AuthProvider ({ children, ...props }) {
  const value = useAuthProvider();
  return (
    <AuthContext.Provider {...props} value={value}>
      {value.currentUser === undefined ? <Loader forceCenter />  : children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
